<template>
    <div class="container">
        <div class="quest-block">
            <div class="quest1">
                <h2>Остались<br>вопросы?</h2>
                <p>Пишите мне, я обязательно отвечу вам ;)</p>
                <div class="chat-bt" @click="openChat">Написать в чат</div>
                <!--<div class="quest-soc-block">
                    <div class="quest-soc wa"><a href=''>Whatsapp</a></div>
                    <div class="quest-soc tl"><a href=''>Telegram</a></div>
                    <div class="quest-soc vk"><a href=''>Vkontake</a></div>
                </div>     -->
            </div>
            <div class="quest2">
                <KImage :src="require('@/assets/book/quest-luda@2x.jpg')" alt=""/>
                <div class="quest-author">
                    <h3>Людмила&nbsp;Кибанова</h3>
                    <p>автор курса</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        methods: {
            openChat() {
                window.userengage("widget.open");
            }
        }
    }
</script>
<style>
    .quest-block {
        display: flex;
        align-items: center;
        justify-content: space-around;
        margin: 100px auto 0 auto;
        max-width: 1000px;
    }

    .quest1 {
        width: 50%;
        position: relative;
    }

    .quest2 {
        width: 40%;
        text-align: center;
        position: relative;
    }

    .quest2 img {
        display: block;
        width: 100%;
    }

    .quest-block h2 {
        margin: 0;
        text-align: left;
        line-height: 60pt;
        margin-bottom: 30px;
    }

    .quest-soc-block {
        margin-top: 30px;
    }

    .quest-soc {
        width: 45px;
        height: 45px;
        display: inline-block;
        text-indent: -50000px;
        margin-right: 50px;
        background-image: url('~@/assets/book/questions.svg');
        background-size: 210px;
        background-repeat: no-repeat;
        cursor: pointer;
        transition: all 0.3s cubic-bezier(.54, 0, .4, 1.51);
    }

    .quest-soc:hover {
        transform: scale(1.1);
    }

    .quest-soc.tl {
        background-position: -88px 0;
    }

    .quest-soc.vk {
        background-position: -170px 0;
    }

    .quest-author {
        position: absolute;
        bottom: -20px;
        left: 50%;
        transform: translateX(-50%);
        text-align: right;
    }

    .quest-author h3 {
        text-transform: initial;
        font-size: 24pt;
        line-height: 24pt;
        margin: 0;
    }

    .quest-author p {
        font: 35pt 'TheArtist', sans-serif;
        position: relative;
        left: 50px;
        top: -20px;
    }

    .chat-bt {
        display: inline-block;
        padding: 15px 40px;
        font-size: 12pt;
        background-color: rgba(255, 177, 164, 0.7);
        cursor: pointer;
        transition: all 0.3s cubic-bezier(.54, 0, .4, 1.51);
        margin-top: 30px;
        font-weight: bold;
    }

    .chat-bt:hover {
        transform: scale(1.1);
    }

</style>