<template>
    <MainScreen/>
    <Features3/>
    <HHeader/>
    <Pricing/>
    <Questions/>
</template>

<script>
    import MainScreen from './HomeView/MainScreen.vue'
    import Features3 from './HomeView/Feauters3.vue'
    import HHeader from './HomeView/HHeader.vue'
    import Pricing from './HomeView/Pricing.vue'
    import Questions from './HomeView/Questions.vue'

    export default {
        name: 'LandingView',
        components: {
            MainScreen,
            Features3,
            HHeader,
            Pricing,
            Questions,
        }
    }
</script>