<template>
    <div class="price-cont">
        <div class="container">
            <div class="pricing-block" id='price'>
                <div
                        :class="{ 'price': true, 'pink': index === 1 }"
                        v-for="(good, index) in getGoods"
                        :key="good.id"
                >
                    <h3> {{good.name}} </h3>
                    <ul>
                        <li :class="{ 'price-no': benefit === '' }" v-for="(benefit, index) in good.benefits" :key="index">
                            <KImage v-if="benefit === ''" :src="require('@/assets/book/price-no.svg')" alt=""/>
                            <span v-else v-html="benefit"></span>
                        </li>
                    </ul>
                    <div class="price-bottom">
                        <p v-if="good.price!=good.priceorig" class='price-p small'>{{good.priceorig}} &#8381;</p>
                        <p class='price-p'>{{good.price}} &#8381;</p>
                        <p v-if="good.count>0" class="price-pl">Осталось {{good.count}} мест</p>                    
                        <button class='main-button price-bt' @click="tryToBuy(good.id)">Записаться</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import KImage from "../Widgets/KImage";
    export default {
        components: {
            KImage
        },
        computed: {
            getGoods () {
                return this.$store.state.goods.filter (good => good.id <= 3)
            }
        },
        methods: {
            openChat(){
                window.userengage("widget.open");
            },

            tryToBuy(id) {
                this.$router.push("/item/"+id)
            }
        }
    }
</script>


<style>
    h3 {
        font-size: 30pt;
        margin-bottom: 50px;
        text-align: center;
        text-transform: uppercase;
    }

    .price-cont {
        position:relative;
    }

        .price-cont::after {
            content: url('../../assets/book/price-bg_1.svg');
            width: 240px;
            height: 160px;
            position: absolute;
            top:0;
            right:50px;
        }

        .price-cont::before {
            content: url('../../assets/book/price-bg_2.svg');
            width: 310px;
            height: 160px;
            position: absolute;
            bottom:0;
            left: 20px;
        }

    .pricing-block {
        display: flex;
        justify-content: space-between;
        line-height: 17pt;
    }

    .price {
        width: 33%;
        padding: 70px 70px 50px 70px;
        text-align: center;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

        .price.pink {
            background-color: rgba(255, 177, 164, 0.5);
        }

        .price.pink::after {
            content: url('../../assets/book/price-hand.svg');
            width: 300px;
            height: 100px;
            /*background-color: royalblue;*/
            position: absolute;
            top: 20px;
            right: 10px;
        }
    
    .price ul {
        text-align: left;
        margin-bottom: 10px;
    }
    
    .price ul li {
        margin-bottom: 20px;
        background-image: url('~@/assets/book/price-check.svg');
        background-size: 12px;
        background-repeat: no-repeat;
        background-position: 0px 6px;
        padding-left: 30px;
    }

    .price ul li.price-no {
        background-image: none;
    }

        .price-no img {
            width: 70px;
        }

    .price-p {
        font: 30pt/30pt 'Kudryashev', sans-serif;
        margin-bottom: 10px;
    }

    .price-p.small {
        font-size:20pt;
        text-decoration: line-through;
        margin-bottom: 0;
        color: #78636a;
    }

    .price-pl {
        font-size: 11pt;
        font-style: italic;        
    }

    .price-bt {
        border: none;
        margin-top: 20px;
        position: absolute;
        left:50%;
        transform: translateX(-50%);
    }

        .main-button.price-bt:hover {
            transform: translateX(-50%) scale(1.1);
        }

    .price-bt::before {
        width: 250px;
        top: -5px;
        left: 0;
    }
</style>